import React from 'react';
import InfoGraphic from './InfoGraphic'
import {graphql, useStaticQuery} from 'gatsby'

const getAboutImages = graphql`
{
    image1: file(relativePath: {eq: "o-nas.jpg"}) {
      childImageSharp {
        fluid {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
}
`
const About = () => {
    const data = useStaticQuery(getAboutImages)
    return (
        <div id="o-nas">
            <div className="about-us">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <div className="section-title text-center">
                                <h2>123 Logistika</h2>
                                <p>Naše logistične storitve za manjše in večje spletne trgovine.</p>
                                <span className="section-title-bg">O nas</span>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-text">
                                <h3>Kako delujemo</h3>
                                <p>Imamo večletne izkušnje v logistiki. Našim poslovnim partnerjem nudimo podporo pri skladiščenju in distributiranju blaga končnim kupcem.</p> 
                
                                <p>Pri 123 Logistika v večini sodelujemo s poslovnimi partnerji, ki se ukvarjajo s spletno prodajo. Kot naš partner poskrbite za delovanje spletne prodaje, blagovno poslovanje pa za vas kvalitetno opravimo mi. Vaše naročeno blago skladiščimo v ustreznem skladišču in ga pripravimo za odpremo. Ko prejmemo naročilo za izdajo blaga, ga pripravimo in odpremimo do končnega kupca.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 o-nas-img">
                            <img src={data.image1.childImageSharp.fluid.src} alt="123 Logistika" />
                        </div>
                    </div>
                </div>  
            </div>
            <InfoGraphic />
        </div>
    )
}

export default About;