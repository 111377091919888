import React from "react"
import Layout from '../components/App/Layout'
import SEO from "../components/App/seo"
import Banner from "../components/Index/Banner"
import Services from "../components/Index/Services"
import About from "../components/Index/About"
import FAQ from "../components/Index/FAQ"
import Contact from "../components/Index/Contact"

const IndexPage = () => (
    <Layout>
        <SEO
            title="123 Logistika"
            description="123 Logistika - Nudimo celovito logistično storitev za manjše in večje spletne trgovine, od naročila do dostave končnemu kopcu. "
        />
        <Banner />
        <div className="mt-130">
            <Services />
        </div>
        <About />
        <FAQ />
        <Contact />
    </Layout>
)

export default IndexPage
