import React, { useState } from 'react'
import PropTypes from "prop-types";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import { Alert } from "react-bootstrap";

import emailjs from "emailjs-com";
import apiKeys from "../apikeys";

const Contact = (props) => {
    const [sendEmail, setSendEmail] = useState("")

    const handleForm = e => {
        e.preventDefault();
        emailjs.sendForm("service_mtbe7hl", apiKeys.TEMPLATE_ID, e.target, apiKeys.USER_ID)
        .then(result => {
            setSendEmail(<Alert variant="success">Sporočilo uspešno poslano</Alert>);
        },
        error => {
            setSendEmail(<Alert variant="danger">Pri pošiljanju je prišlo do napake! Prosimo poskusite znova. Pišite nam lahko tudi na elektronski naslov info@123logistika.com.</Alert>);
        })
    }
    return (
        <section id="vizitka" className="contact-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <div className="section-title">
                            <h2>{props.sectionTitle}</h2>
                            <p>{props.sectionDescription}</p>
                            <span className="section-title-bg">{props.SectionbgTitle}</span>
                        </div>
                    </div>
                </div>

                <div className="row address-area">
                        <div className="col-lg-4 col-md-4">
                            <div className="address">
                                <i className="fa fa-map-marker"></i>
                                <h4>{props.AddTitle}</h4>
                                <p>{props.Address}</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="email">
                                <i className="fa fa-envelope"></i>
                                <h4>{props.EmailTitle}</h4>
                                <p>{props.Email}</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="phone">
                                <i className="fa fa-phone"></i>
                                <h4>{props.PhoneTitle}</h4>
                                <p>{props.Phone}</p>
                            </div>
                        </div>
                

                    {/* <div className="col-lg-8 col-md-8">
                        <div className="contact-form">
                            <ValidationForm
                                id="contactForm"
                                onSubmit={handleForm}
                            >
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <TextInput
                                                name="name"
                                                id="name"
                                                required
                                                successMessage=""
                                                errorMessage="Prosimo vpišite vaše ime"
                                                className="form-control"
                                                placeholder="Vaše ime"
                                                autoComplete="off"
                                            />
                                            <div className="help-block with-errors" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <TextInput
                                                name="email"
                                                id="email"
                                                type="email"
                                                required
                                                successMessage=""
                                                errorMessage="Prosimo vnesite veljavni elektronski naslov"
                                                className="form-control"
                                                placeholder="Elektronski naslov"
                                                autoComplete="off"
                                            />
                                            <div className="help-block with-errors" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <TextInput
                                                name="podjetje"
                                                id="podjetje"
                                                type="text"
                                                successMessage=""
                                                errorMessage=""
                                                className="form-control"
                                                placeholder="Podjetje"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <TextInput
                                                name="number"
                                                id="number"
                                                type="text"
                                                successMessage=""
                                                errorMessage=""
                                                className="form-control"
                                                placeholder="Telefonska številka"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
 
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <TextInput
                                                name="message"
                                                id="description"
                                                multiline
                                                placeholder="Sporočilo"
                                                className="form-control"
                                                required
                                                successMessage=""
                                                errorMessage="Prosimo napišite sporočilo"
                                                rows="5"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                                { sendEmail }

                                <div className="center-wrap text-center">
                                    <div className="button">
                                        <button type="submit">Pošlji sporočilo <i className="fa fa-long-arrow-right"></i> </button>
                                        <div className="mask"></div>
                                    </div>
                                </div>
                                <div className="clearfix" />
                            </ValidationForm>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

//Props Types
Contact.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    AddTitle: PropTypes.string,
    Address: PropTypes.string,
    EmailTitle: PropTypes.string,
    Email: PropTypes.string,
    PhoneTitle: PropTypes.string,
    Phone: PropTypes.string,
}

//Default Props
Contact.defaultProps = {
    SectionbgTitle: "Vizitka",
    sectionTitle: "Pokličite nas",
    sectionDescription: "Z veseljem se z vami dobimo se na kavi, kjer se pogovorimo o vaših logističnih potrebah in pričakovanjih ter najdemo skupno rešitev za dolgoročno zanesljivo sodelovaje.",
    AddTitle: "Naša lokacija",
    Address: "Stegne 21 C",
    EmailTitle: "E-mail",
    Email: "info@123logistika.com",
    PhoneTitle: "Telefon",
    Phone: "+386 41 468 764",
}

export default Contact
