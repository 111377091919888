import React from 'react'
import WelcomeServices from './WelcomeServices'
import PropTypes from "prop-types";
import Skladiscenje from "../../images/skladiscenje.svg"
import RazkladInPrevzem from "../../images/razklad-in-prevzem.svg"
import VodenjeZalog from "../../images/vodenje-zalog.svg"
import Etiketiranje from "../../images/Etiketiranje.svg"
import Odprema from "../../images/odprema.svg"
import Dostava from "../../images/dostava.svg"

const Services = (props) => {
    const servicedata = props.servicesData.map((service, index) => (
        <div className="col-md-6 col-lg-4 text-center" key={index}>
            <div className="service-item">
                <div className="service-icon">
                    {service.svg}
                </div>
                <h3>{service.heading}</h3>
                <p>{service.description}</p>
            </div>
        </div>
    ));
    return (
        <div id="storitve">
            <section className="services ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <div className="section-title">
                                <h2>{props.sectionTitle}</h2>
                                <p>{props.sectionDescription}</p>
                                <span className="section-title-bg">{props.SectionbgTitle}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {servicedata}
                    </div>
                </div>
            </section>
            <WelcomeServices />
        </div>
    )
}

Services.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Services.defaultProps = {
    SectionbgTitle: "Storitve",
    sectionTitle: "Naše storitve",
    sectionDescription:
        "Nudimo celovito logistično storitev za manjše in večje spletne trgovine, od naročila do dostave končnemu kupcu.",

    servicesData: [
        {
            svg: <img alt="razklad in prevzem" src={RazkladInPrevzem} />,
            heading: "Razklad in prevzem",
            description:
                "Vaše naročeno blago raztovorimo in kvalitetno prevzamemo."
        },
        {
            svg: <img alt="skladiščenje" src={Skladiscenje} />,
            heading: "Skladiščenje",
            description:
                "Raztovorjeno blago pozicioniramo v našem skladišču, kjer počaka na odpremo."
        },
        {
            svg: <img alt="vodenje zalog" src={VodenjeZalog} />,
            heading: "Vodenje zalog",
            description:
                "Ažurno vodimo zaloge vašega blaga od prihoda do odhoda."
        },
        {
            svg: <img alt="etiketiranje, deklariranje" src={Etiketiranje} />,
            heading: "Etiketiranje, deklariranje ...",
            description:
                "Po željah in potrebah opremimo vaše blago z ustreznimi označbami."
        },
        {
            svg: <img alt="priprava na odpremo" src={Odprema} />,
            heading: "Priprava na odpremo",
            description:
                "Na podlagi naročila vaše blago ali artikle ustrezno zapakiramo in pripravimo na odpremo."
        },
        {
            svg: <img alt="dostava" src={Dostava} />,
            heading: "Predaja v dostavo",
            description:
                "Pripravljene pošiljke naslovimo in izročimo kurirju v dostavo."
        },
    ]
}

export default Services
