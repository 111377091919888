import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'

const infoImage = graphql`
{
    infoImage: file(relativePath: {eq: "123-logistika-grafika-proces.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
}
`

const InfoGraphic = () => {
    const data = useStaticQuery(infoImage)
    return (
        <section className="nas-proces">
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <div className="section-title nas-proces-grafika">
                            <h1>Kako poteka naš proces</h1>
                            <div className="nas-proces-grafika-img">
                                <img src={data.infoImage.childImageSharp.fluid.src} alt="123Logistika - Naš proces" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InfoGraphic
