import React from 'react'
import PropTypes from "prop-types";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';
import { Link } from 'gatsby';
import ScrollAnimation from 'react-animate-on-scroll';

const FAQ = (props) => {
    return (
        <section id="pogosta-vprasanja" className="faq ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <div className="section-title">
                            <h2>{props.sectionTitle}</h2>
                            <p>{props.sectionDescription}</p>
                            <span className="section-title-bg">{props.SectionbgTitle}</span>
                        </div>
                    </div>
                </div>
                <div className="faq-content">
                    <div className="row">
                        <div className="col-lg-4">
                            <Link to={props.ContentLink}>
                                <div className="content-box color-effect-1">
                                    <h3>{props.ContentTitle}</h3>

                                    <div className="box-icon-wrap box-icon-effect-1 box-icon-effect-1a">
                                        <div className="box-icon"><i className="fa  fa-mail-forward"></i></div>
                                    </div>

                                    <p>{props.ContentDescription}</p>
                                </div>
                            </Link>
                        </div>

                        <div className="col-lg-8">
                            <div className="accordion-content">
                                <Accordion>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Kako začnemo s sodelovanjem?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Dobimo se na kavi, kjer se pogovorimo o vaših logističnih potrebah in pričakovanjih ter najdemo skupno rešitev za dolgoročno zanesljivo sodelovaje.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Kako hitro poteka odprema pošiljk?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Ko dobimo naročilo, ga odpremimo v najkrajšem možnem času. Praviloma naročila oddana do 12ih pripravimo in predamo kurirjo še isti delovni dan.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Ali poslujete tudi izven delovnega časa?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Po potrebi lahko na podlagi dogovora delujemo v nočnem času, med vikendi in dela prostimi dnevi.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                S katerimi dostavnnimi službami sodelujemo? 
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Sodelujemo s Pošto Slovenije, GLS, DHL, DPD in drugimi.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Ali obstajajo omejitve pri skladiščenju izvenstandardnega blaga? 
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Večino blaga lahko skladiščimo brez omejitev, za morebitne omejitve nam pa lahko pišete na elektronski naslov info@123logistika.com.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Na kakšen način poteka sprejem naročil?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Naročilo se k nam posreduje po elektronski poti na podlagi medsebojnega dogovora.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

//Props Types
FAQ.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    ContentLink: PropTypes.string,
    ContentTitle: PropTypes.string,
    ContentDescription: PropTypes.string,
    faqData: PropTypes.array
}

//Default Props
FAQ.defaultProps = {
    SectionbgTitle: "FAQ",
    sectionTitle: "Pogosta vprašanja",
    sectionDescription: "Nekaj odgovorov na vaša pogosta vprašanja",
    ContentTitle: "Dodatna vprašanja?",
    ContentDescription: "V primeru dodatnih vprašanj nas lahko brez zadržkov kontaktirate preko spodnjega obrazca. Odgovorili vam bomo v najkrajšem možnem času.",
    ContentLink: "#vizitka",
}

export default FAQ
